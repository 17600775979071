<template>
  <div class="window-height window-width mh-630 bg-login-img">
    <div class="row window-height">
      <div class="col-12 row justify-center items-end">
        <div class="w100p row items-center justify-center q-px-xl q-pb-sm">
          <img src="../assets/img/logo.svg" alt="LOGO" class="h150">
        </div>
        <q-form @submit.prevent="validarLogin" class="w350 row justify-center bg-white q-pa-lg">
          <div class="text-h4 text-grey-system fs-w-800 q-mb-xl">Bienvenido</div>
          <q-input
                  :rules="[ val => val && val.length > 0 || 'Campo requerido']"
                  borderless
                  class="login-input q-mb-xl w100p"
                  color="primary"
                  label-color="primary"
                  dense
                  autofocus
                  lazy-rules="ondemand"
                  placeholder="Usuario"
                  v-model="usuario">
            <template #prepend>
              <q-icon name="mdi-account-outline"/>
            </template>
          </q-input>
          <q-input
                  :rules="[ val => val && val.length > 0 || 'Campo requerido']"
                  borderless
                  class="login-input q-mb-xl w100p"
                  color="primary"
                  label-color="primary"
                  dense
                  lazy-rules="ondemand"
                  placeholder="Password"
                  type="password"
                  v-model="password"
          >
            <template #prepend>
              <q-icon name="mdi-lock-outline"/>
            </template>
          </q-input>
          <q-btn class="btn-fs14" color="positive" label="Ingresar" padding="8px 60px" type="submit" unelevated/>
        </q-form>
        <div class="w100p row items-center justify-center q-px-xl q-pb-lg">
          <div class="q-ml-md text-white">Berhock 2021. Todos los derechos reservados.</div>
        </div>
        <div class="w100p row items-right justify-end q-px-xl q-pb-lg">
          <div class="q-ml-md text-blue-grey-13">V20210715-1506</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import LoginServices from "../Services/LoginServices";
  import configStorage from '../Services/Global/getConfig';

  const loginService = new LoginServices();
  export default {
    name: "login",
    data() {
      return {
        usuario: "",
        password: "",
        validacion: false,
      }
    },
    mounted() {
      this.loader(false);
      this.axiosSourceToken = this.axiosCancelToken.source();

      configStorage.getApi();
    },
    methods: {
      async validarLogin() {
        this.loader(true);
        
        await this.realizarLoginExterno();
        if(this.validacion)
          await this.realizarLogin();
        
        this.loader(false);
      },
      async realizarLogin() {
        await loginService.realizarLogin(this.usuario)
          .then(() => {            
            this.$router.push("/home");
          })
          .catch(error => {
            this.alertShow(error, "error");
            this.loader(false);
          });
      },
      async realizarLoginExterno() {
        await loginService.realizarLoginExterno(this.usuario, this.password)
          .then((res) => {
            let data = res.datos;

            if(data)
              this.validacion = true;
            else 
              return this.alertShow("No existe usuario en directorio", "error");
          })
          .catch(error => {
            this.alertShow(error, "error");
            return this.loader(false);
          });
      },
    }
  }
</script>
<style>
  .bg-login-img {
    background-image: url("../assets/img/bg-login.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    z-index: 1;
  }
  .v-align-center{
    margin-top: 40%;
    margin-bottom: 40%;
  }
  .login-input.q-field--error {margin-bottom: 48px!important;}
  .login-input.q-field--dense .q-field__control, .login-input.q-field--dense .q-field__marginal {
    height: 40px!important;
    border-bottom: 1px solid #003963;
  }
  .btn-fs14 span.block {font-size: 14px!important;}
  .w-auto {width: auto}
  .h-auto {height: auto}
</style>
